import React, { Component } from 'react';

import { Button, Card, Elevation } from "@blueprintjs/core";

class Videos extends React.Component {
  render() {
    return (
        <div className='videos'>
            <div> This is a music video I made for xJL's "Sunshine in Disguise" </div>

            <div className='cards'>
                <Card elevation={Elevation.TWO}>
                    <div class="youtubeVideo"><iframe width="625" height="400" frameborder="0" allowfullscreen="" src="//www.youtube.com/embed/W41P4ALQ18o?autohide=2&amp;autoplay=0&amp;cc_load_policy=0&amp;color=red&amp;controls=1&amp;disablekb=0&amp;fs=1&amp;iv_load_policy=1&amp;loop=0&amp;modestbranding=0&amp;rel=1&amp;showinfo=1&amp;theme=dark"></iframe></div>
                </Card>
            </div>

            <div>These are videos I've made for <a href="http://www.carshowz.com">CarShowz.com</a></div>

            <div className='cards'>
                <Card elevation={Elevation.TWO}>
                    <div class="youtubeVideo"><iframe width="625" height="400" frameborder="0" allowfullscreen="" src="//www.youtube.com/embed/Cf42zlBk8tE?autohide=2&amp;autoplay=0&amp;cc_load_policy=0&amp;color=red&amp;controls=1&amp;disablekb=0&amp;fs=1&amp;iv_load_policy=1&amp;loop=0&amp;modestbranding=0&amp;rel=1&amp;showinfo=1&amp;theme=dark"></iframe></div>
                </Card>

                <Card elevation={Elevation.TWO}>
                    <div class="youtubeVideo"><iframe width="625" height="400" frameborder="0" allowfullscreen="" src="//www.youtube.com/embed/YMHYr5XecHY?autohide=2&amp;autoplay=0&amp;cc_load_policy=0&amp;color=red&amp;controls=1&amp;disablekb=0&amp;fs=1&amp;iv_load_policy=1&amp;loop=0&amp;modestbranding=0&amp;rel=1&amp;showinfo=1&amp;theme=dark"></iframe></div>
                </Card>

                <Card elevation={Elevation.TWO}>
                    <div class="youtubeVideo"><iframe width="625" height="400" frameborder="0" allowfullscreen="" src="//www.youtube.com/embed/pAPEMmBPG1M?autohide=2&amp;autoplay=0&amp;cc_load_policy=0&amp;color=red&amp;controls=1&amp;disablekb=0&amp;fs=1&amp;iv_load_policy=1&amp;loop=0&amp;modestbranding=0&amp;rel=1&amp;showinfo=1&amp;theme=dark"></iframe></div>
                </Card>
            </div>
        </div>
    );
  }
}
export default Videos;
