import React, { useState } from 'react';

import { Button, Card, Elevation } from "@blueprintjs/core";

import { CopyBlock, dracula } from "react-code-blocks";

function Interview() {
    const [sumInput, setSumInput] = useState(null);
    const [sumOutput, setSumOutput] = useState(null);
    const [fibInput, setFibInput] = useState(null);
    const [fibOutput, setFibOutput] = useState(null);

    function sum(prev, curr) {
        let output = prev + curr;
        return output;
    }

    function handleSumInput(event) {
        let input = event.target.value;
        let output = 0;


        for (let i = 0; i <= input; i++){
            output = sum(output, i);
        }

        setSumInput(event.target.value);
        setSumOutput(output);
    }

    function handleFibInput(event) {
        let input = event.target.value;
        let prevOut = 0;
        let output = 1;

        for (let i = 2; i <= input; i++){
            if (i <= 1) {
                output = 1;
            }

            let temp = output;
            output = output + prevOut;
            prevOut = temp;
        }

        setFibInput(event.target.value);
        setFibOutput(output);
    }

    return (
        <div className='development'>
            <Card elevation={Elevation.TWO} className='development-section'>

                <h3>
                    Sum to X
                </h3>

                <div>
                    Find the sum of numbers upto the input
                    (eg: Input of 5 yields 1 + 2 + 3 + 4 + 5 = 15)
                </div>

                <div>
                    <label>
                        <div> Input: </div>
                        <input type='text' value={sumInput} onChange={handleSumInput} />
                    </label>
                </div>

                <div>
                    Output: {sumOutput}
                </div>

                <div> One of many possible ways to do this: </div>

                <CopyBlock
                    language='javascript'
                    text={`
                        sum(prev, curr) {
                            let output = prev + curr;
                            return output;
                        }

                        let input = event.target.value; // from input
                        let output = 0;

                        for (let i = 0; i <= input; i++){
                            output = this.sum(output, i);
                        }

                        console.log(output);
                    `}
                    theme={dracula}
                    wrapLine={true}
                    codeBlock
                />

            </Card>

            <Card elevation={Elevation.TWO} className='development-section'>

                <h3>
                    Fibonacci Sequence
                </h3>

                <div>
                    In the Fibonacci sequence, the next number is found by adding up the two numbers before it.
                    (eg: 1 1 2 3 5 8 13 21)
                </div>

                <div>
                    <label>
                        <div> Fibonacci Input: </div>
                        <input type='text' value={fibInput} onChange={handleFibInput} />
                    </label>
                </div>

                <div>
                    Output: {fibOutput}
                </div>

                <div> One of many possible ways to do this: </div>

                <CopyBlock
                    language='javascript'
                    text={`
                        let input = event.target.value; // from input
                        let prevOut = 0;
                        let output = 1;

                        for (let i = 2; i <= input; i++){
                            if (i <= 1) {
                                output = 1;
                            }

                            let temp = output;
                            output = output + prevOut;
                            prevOut = temp;
                        }

                        console.log(output);
                    `}
                    theme={dracula}
                    wrapLine={true}
                    codeBlock
                />

                <div> A recursive option: </div>

                <CopyBlock
                    language='javascript'
                    text={`
                        function fibonacci(input) {
                            if (input <= 1) return 1;

                            return fibonacci(input - 1) + fibonacci(input - 2);
                        }
                    `}
                    theme={dracula}
                    wrapLine={true}
                    codeBlock
                />

            </Card>
        </div>
    );
}

export default Interview;
