import React, { Component } from 'react';

import { Button, Card, Elevation } from "@blueprintjs/core";

class Whitepaseo extends React.Component {
  render() {
    return (
        <div>
        <Card elevation={Elevation.TWO}>
            <table id="mods" width="30%" align="left" border="1">
                <tbody>
                    <tr>
                        <td><b>1997 Toyota Paseo Coupe</b>
                        </td>
                    </tr>
                    <tr>
                        <td>Purchased: May 2003
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Status: Totaled (April 2005)
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td><b>Modifications</b>
                        </td>
                    </tr>
                    <tr>
                        <td><u>Exterior</u>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            -Cynos Headlights/Corner Lights
                        </td>
                    </tr>
                    <tr>
                        <td>-Twin Color Fogs (Blue/White)
                        </td>
                    </tr>
                    <tr>
                        <td>-15" Gun Metal Rims
                        </td>
                    </tr>
                    <tr>
                        <td>-Large Vinyl
                        </td>
                    </tr>
                    <tr>
                        <td>-Custom Taillights
                        </td>
                    </tr>
                    <tr>
                        <td>-Terminator II Spoiler
                        </td>
                    </tr>
                    <tr>
                        <td>-Blue Underglow LED Kit
                        </td>
                    </tr>
                    <tr>
                        <td>-Blue Hood Lights
                        </td>
                    </tr>
                    <tr>
                        <td>-Blue Strobes
                        </td>
                    </tr>
                    <tr>
                        <td>-Fsudo Muffler
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td><u>Interior</u>
                        </td>
                    </tr>
                    <tr>
                        <td>-Racing Pedals
                        </td>
                    </tr>
                    <tr>
                        <td>-Short Shifter
                        </td>
                    </tr>
                    <tr>
                        <td>-Painted Interior
                        </td>
                    </tr>
                    <tr>
                        <td>-Custom Shiftboot
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td><u>Audio</u>
                        </td>
                    </tr>
                    <tr>
                        <td>-Pioneer MP3 Headunit
                        </td>
                    </tr>
                    <tr>
                        <td>-Poineer Speakers
                        </td>
                    </tr>
                    <tr>
                        <td>-Pioneer 10" Sub
                        </td>
                    </tr>
                    <tr>
                        <td>-Crunch Amp
                        </td>
                    </tr>
                    <tr>
                        <td>-Custom Enclosure
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <td><u>Engine</u>
                        </td>
                    </tr>
                    <tr>
                        <td>-Strut Bar
                        </td>
                    </tr>
                    <tr>
                        <td>-Weapon R Intake
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className='car-images images'>
                <img src={require('../../images/automotive/wp1.jpg')} />
                <img src={require('../../images/automotive/wp2.jpg')} />
                <img src={require('../../images/automotive/wp3.jpg')} />
                <img src={require('../../images/automotive/wp4.jpg')} />
                <img src={require('../../images/automotive/wp5.jpg')} />
            </div>
            </Card>
        </div>
    );
  }
}
export default Whitepaseo;
