import React, { Component } from 'react';

class About extends React.Component {
  render() {
    const { name = 'About' } = this.props;
    return (
        <div className='about'>
            <div>
                My name is Brian. I was born in Maryland and have spent my whole life in the surrounding vicinity.
                I attended a technical high school with a focus in Engineering, and continued on with Computer Engineering for my B.S.
                Over the years I've done whatever I can to learn something new. I tend to enjoy everything I do and sometimes find
                it hard to prioritize one hobby over another. My typical outdoor activities include: kayaking, playing tennis,
                and working on/fixing/driving cars. My inside activities include: homebrewing both beer and wine, growing food in my
                garden, working on computers, mobile app development, playing guitar, drums, or piano, and fixing up my house.
                As a full time software developer I try to spend additional time keeping up with new technologies I'm not
                actively using by working on various websites and helping other developers.
            </div>

            <div>
                This site is currently built using React 16, along with <a href='https://blueprintjs.com/' target='_blank'> BluePrintJS</a>.
            </div>

            <div>

            </div>

            <div> The best way to contact me? Click here -> <a href="mailto:brian@insaneintention.com">email me!</a> </div>

        </div>
    );
  }
}
export default About;
