import React, { Component } from 'react';

import { Button, Card, Elevation } from "@blueprintjs/core";

class Corners extends React.Component {
  render() {
    return (
        <div className='custom-corners'>
            <Card elevation={Elevation.TWO}>
                <h3>
                    Custom Corner Lights
                </h3>
                <table width="85%" id="Table1">
                    <tbody><tr>
                        <td>
                            <p>
                                <b>Needed materials/tools:</b>
                            </p>
                            <p>Bondo Fiberglass Repair Kit - Box with Resin and separate sheet of fiberglass
                                cloth
                            </p>
                            <p>Bondo Fiberglass Reinforced Filler - Paint Can with fiberglass strips mixed in
                                the resin
                            </p>
                            <p>
                            Sand Paper - Assorted Grits - Wet and Dry - or to your liking
                            </p>
                            <img src={require('../../images/automotive/corners2.jpg')} align="right" />
                            <p></p>
                            <p>Sanding Block
                            </p>
                            <p>Dremel/Rotary Tool
                            </p>
                            <p>Drill and Hole Saw
                            </p>
                            <p>PVC Pipe
                            </p>
                            <p>Gloves - Use as desired
                            </p>
                            <p>Mask - Use as desired
                            </p>
                            <p>&nbsp;</p>
                            <p><b>Directions</b>
                            </p>
                            <p><b>Step 1</b>: Remove stock corners from car (or - highly suggested - purchase a
                                second set of lights). Clean them up, and scuff up the outer lens with ~150
                                grit sand paper.
                            </p>
                            <p><b>Step 2</b>: Cut a sheet of fiberglass cloth large enough to cover the entire
                                corner light. Lay the cloth over the light and mix the Bondo resin. Spread the
                                Bondo resin evenly over the fiberglass cloth and smooth it out. Allow
                                recommended time to dry (about 40min) or until the resin is no longer sticky.
                            </p>
                            <p><b>Step 3</b>: While waiting for the resin to dry, mix more of the fiberglass
                                resin and coat your PVC with the it (inside and out). Once this
                                dries add a 2nd coat to the PVC. (I recommend standing the roll on an end on
                                wax paper - it'll peel right off when it dries)
                            </p>
                            <p><img src={require('../../images/automotive/corners1.jpg')} align="left" /></p>
                            <p><b>Step 4</b>: On the light, trim off excess fiberglass cloth from the edges
                                with scissors, then either hand sand, or use the dremel and circular sanding
                                attachment to take the fiberglass cloth smooth to the edges of the light. Sand
                                the top of the light to smooth out excess resin with the sanding block and ~60
                                grit sand paper.
                            </p>
                            <p><b>Step 5</b>: Pick the spot where you want the hole in the light to be. Roughly
                                center from top/bottom. Closer to the inner part of the car left/right. (Use
                                pictures as reference). Using the PVC, trace the circle where you
                                want it. Using a roughly 1" Circle cutter and drill, drill out the hole you
                                just marked. From there, draw a roughly egg shaped circle using the circle you
                                just cut as a reference. Using the dremel cut out the area you just marked off
                                and smooth it out with sand paper.
                            </p>
                            <p><b>Step 6</b>: This is the "not very exact science" part. Now that the hole is
                                cut, and your PVC is dry, stick it in the hole.
                            </p>
                            <p>This part is up to you. You can do something fancy and cut out the back of the
                                light and insert a fog lamp or something else, or just slide the PVC
                                over the orange cylinder already in the light. This may take some more
                                adjustment of the hole you cut, but that's ok. You'll fill any part that you
                                over-cut later on anyway. Adjust the hole till you have the roll wherever you
                                want it making sure the roll is sticking out of the light enough that it's not
                                flush with the light.
                            </p>
                            <p><b>Step 7</b>: Now that you have the roll lined up, mix together some of the
                                Bondo with the cut up piece of fiberglass cloth already mixed in. Spread the
                                mixture between the light housing and the PVC (Try to keep it out
                                of the inside of the PVC, it'll make life easier). Make sure all the holes are
                                filled and smooth out what you can. Allow it to dry.
                            </p>
                            <p><b>Step 8</b>: Using the dremel again, cut all of the excess PVC off.
                            Then using either the sanding block or the dremel with a sanding
                                circle/disc, smooth out the bondo on the light.
                            </p>
                            <p><b>Step 9</b>: Cut another sheet of fiberglass cloth the size of the light.
                                Basically repeat Step 2 and Step 4. After it's all dry, cut out the circle
                                again using the dremel (this will be a lot easier this time around).
                            </p>
                            <p><b>Step 10</b>: Sand everything smooth.
                            </p>
                            <p><b>Step 11</b>: Primer. Sand. Primer. Sand. Paint. Sand. Paint. Sand. Clear.
                                Sand. Clear.
                            </p>
                            <p><b>Step 12</b>: Install lights, show off.
                            </p>
                            <p></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            </Card>
        </div>
    );
  }
}
export default Corners;
