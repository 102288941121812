import React, { Component } from 'react';

import { Button, Card, Elevation } from '@blueprintjs/core';

class Home extends React.Component {
    render() {
        return (
            <div className='home'>
                <img src={require('../images/home/banner.gif')} />
                <h1>Thanks for coming!</h1>
                <div className='intro'>
                    <div>
                    This site is my virtual portfolio. It is also way for me to show off.  
                    Here I show everything from software development, to wood working projects, automotive customization, video editing, and 3D Printing.
                    Follow the links above to view the chronicles of various design projects I have taken up over the last few years.
                    </div>
                </div>

                <div className='cards'>

                    <Card interactive={true} elevation={Elevation.TWO}>
                        <h5>Who am I?</h5>
                        <p>My name is Brian. I am a professional UI developer, but I'm also just another guy that likes to tinker with things.</p>
                    </Card>

                    <Card interactive={true} elevation={Elevation.TWO}>
                        <h4>What do I do?</h4>
                        <p>
                            Where to begin!? I develop software with all sorts of various languages ranging from Basic to React, and lots in between.
                            I rip apart cars, and put them back together. I don't believe in leaving a cell phone in a factory setting.
                            I build things from wood using any tool I can find. I play a variety of instruments,
                            but spend most of my time with guitars.
                        </p>
                    </Card>

                    <Card interactive={true} elevation={Elevation.TWO}>
                        <h3>Why do I do it?</h3>
                        <p>
                            If it has moving parts, I have to see why, and then I see how can I change it.
                            I want to make things different and better. Put simply, I love to tinker.
                        </p>
                    </Card>

                </div>
            </div>
        );
    }
}
export default Home;
