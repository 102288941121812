import React, { Component } from 'react';

import { Button, Card, Elevation } from "@blueprintjs/core";

const Printing = () => {
    return (
        <div className='development'>
            <div>
                This page shows some of my current 3D Design and 3D Printing projects.
                <div>View my full Thingiverse profile: <a href="https://www.thingiverse.com/insaneintenti0n/designs" target="_blank"> https://www.thingiverse.com/insaneintenti0n/designs </a> </div>
            </div>

            <Card elevation={Elevation.TWO} className='development-section typing-practice'>
                <h3>
                    Mario Kart KNex Car
                </h3>

                <div>
                    I designed these car pieces modeled closely after real Mario Kart KNex cars in 3D Builder to be used along with the track pieces below.
                </div>

                <div className='dev-center'>
                    <div>
                        <a href='https://www.thingiverse.com/thing:4787905' target='_blank' title='Click to go to thingiverse'>
                            <img src={require('../images/printing/knexcar.png')} />
                        </a>
                    </div>
                </div>
            </Card>

            <Card elevation={Elevation.TWO} className='development-section typing-practice'>
                <h3>
                    Mario Kart KNex Track Replacements
                </h3>

                <div>
                    I designed these track pieces in 3D Builder to be used as replacement parts.
                </div>

                <div className='dev-center'>
                    <div>
                        <a href='https://www.thingiverse.com/thing:4785000' target='_blank' title='Click to go to thingiverse'>
                            <img src={require('../images/printing/knex.png')} />
                        </a>
                    </div>
                </div>
            </Card>

            <Card elevation={Elevation.TWO} className='development-section typing-practice'>
                <h3>
                    Mario Cabinet Knobs
                </h3>

                <div>
                    I designed these in 3D Builder using other available designs to be printed on a 3D Printer.
                </div>

                <div className='dev-center'>
                    <div>
                        <a href='https://www.thingiverse.com/thing:4783679' target='_blank' title='Click to go to thingiverse'>
                            <img src={require('../images/printing/knob.png')} />
                        </a>
                    </div>
                </div>
            </Card>

            <Card elevation={Elevation.TWO} className='development-section typing-practice'>
                <h3>
                    Mario Warp Pipe Tap Handle
                </h3>

                <div>
                    I designed this warp pipe in 3D Builder to be printed to use on a kegorator.
                </div>

                <div className='dev-center'>
                    <div>
                        <a href='https://www.thingiverse.com/thing:4781535' target='_blank' title='Click to go to thingiverse'>
                            <img src={require('../images/printing/mariopipe.png')} />
                        </a>
                    </div>
                </div>
            </Card>

            <Card elevation={Elevation.TWO} className='development-section typing-practice'>
                <h3>
                    Princess Peach Wall Clock
                </h3>

                <div>
                    I designed this clock in 3D Builder using other available designs to be printed on a 3D Printer.
                </div>

                <div className='dev-center'>
                    <div>
                        <a href='https://www.thingiverse.com/thing:4781973' target='_blank' title='Click to go to thingiverse'>
                            <img src={require('../images/printing/peachclock.png')} />
                        </a>
                    </div>
                </div>
            </Card>
        </div>
    );
}
export default Printing;
