import React, { Component } from 'react';

import { Button, Card, Elevation } from "@blueprintjs/core";

class Development extends React.Component {
    render() {
        return (
            <div className='development'>
                <div>
                    This page shows some of the various programming and development I've done over the years, with a lot of examples missing or defunct.
                </div>

                <Card elevation={Elevation.TWO} className='development-section typing-practice'>
                    <h3>
                        typing-practice
                    </h3>

                    <div>
                        This is a small single html file that was created to help local elementary school students practice
                        typing.  It is based on a list of sight words common for Pre-K through 1st grade students.  Rather than
                        focus on finger position and single key typing, this familiarizes children with common words to type.
                    </div>

                    <div className='dev-center'>
                        <div>
                            <a href='http://www.insaneintention.com/typing-practice.html' target='_blank'>
                                <img src={require('../images/development/typing-practice.jpg')} />
                            </a>
                        </div>
                        <div>
                            github: <a href='https://github.com/insaneintenti0n/typing-practice' target='_blank'> typing-practice </a>
                        </div>
                    </div>
                </Card>

                <Card elevation={Elevation.TWO} className='development-section typing-practice'>
                    <h3>
                        math-practice
                    </h3>

                    <div>
                        This is a small single html file that was created to help local elementary school students practice
                        basic math problems.  Current focus is only addition and subtraction.
                    </div>

                    <div className='dev-center'>
                        <div>
                            <a href='http://www.insaneintention.com/math.html' target='_blank'>
                                <img src={require('../images/development/math-practice.png')} />
                            </a>
                        </div>
                        <div>
                            github: <a href='https://github.com/insaneintenti0n/math-practice' target='_blank'> math-practice </a>
                        </div>
                    </div>
                </Card>

                <Card elevation={Elevation.TWO} className='development-section login-practice'>
                    <h3>
                        login-practice
                    </h3>

                    <div>
                        This is a small single html file that was created to help local elementary school students practice
                        logging into a computer.  It allows a parent or other adult to enter the student's assigned username
                        and password, and then lets the child practice typing their information while receiving feedback on if the
                        entered information was correct.
                    </div>

                    <div className='dev-center'>
                        <div>
                            <a href='http://www.insaneintention.com/login-practice.html' target='_blank'>
                                <img src={require('../images/development/login-practice.jpg')} />
                            </a>
                        </div>
                        <div>
                            github: <a href='https://github.com/insaneintenti0n/login-practice' target='_blank'> login-practice </a>
                        </div>
                    </div>
                </Card>

                <Card elevation={Elevation.TWO} className='development-section interview'>
                    <h3>
                        Common JavaScripty Interview Questions
                    </h3>

                    <div>
                        This is just a list of typical interview questions and some varying answers.
                    </div>

                    <div className='dev-center'>
                        <div>
                        <div>
                            <a href='/development/interview'><img src={require('../images/development/code.jpg')} /></a>
                        </div>
                        </div>
                    </div>
                </Card>

                <Card elevation={Elevation.TWO} className='development-section login-practice'>
                    <h3>
                        Fun With Dots
                    </h3>

                    <div>
                        Initially developed as a mobile application, this is a small jQuery application that
                        let the user free draw, create a digital text scroller, or view a digital clock.
                    </div>

                    <div className='dev-center'>
                        <div>
                            <a href='http://www.insaneintention.com/dots/index.html' target='_blank'>
                                <img src={require('../images/development/dots.png')} />
                            </a>
                        </div>
                    </div>
                </Card>

                <Card elevation={Elevation.TWO} className='development-section'>
                    <h3>
                        CarShowz.com Mobile app
                    </h3>

                    <div>
                        This mobile application was developed for CarShowz.com.  The app took an aggregation of the car shows
                        listed on CarShowz.com, used the user's location via GPS lookup, or user-entered location, and displayed
                        car shows that were available in the selected date range.  The user could then add the car show to their
                        calendar, look up directions, and see the upcoming weather in the area.
                    </div>

                    <div className='dev-center carshowz-images'>
                        <img src={require('../images/development/carshowz_icon.jpg')} />
                        <div>
                            <img src={require('../images/development/carshowz_search.png')} />
                            <img src={require('../images/development/carshowz_show.png')} />
                        </div>
                    </div>

                    <div>
                        Head to the <a href='videos'> videos </a> page to watch the How-to Use video.
                    </div>
                </Card>

                <Card elevation={Elevation.TWO} className='development-section'>
                    <h3>
                        Flash Paseo Builder
                    </h3>

                    <div>
                        An interative Toyota Paseo designer.
                    </div>

                    <div className='dev-center'>
                        <object id='ClubSeo - 2006.05.01' codeBase='http://fpdownload.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=7,0,0,0'
        					height='400' width='600' align='middle' classid='clsid:d27cdb6e-ae6d-11cf-96b8-444553540000'>
        					<param name='_cx' value='15875' />
        					<param name='_cy' value='10583' />
        					<param name='FlashVars' value='' />
        					<param name='Movie' value='flash/ClubSeo - 2006.05.01.swf' />
        					<param name='Src' value='flash/ClubSeo - 2006.05.01.swf' />
        					<param name='WMode' value='Window' />
        					<param name='Play' value='0' />
        					<param name='Loop' value='-1' />
        					<param name='Quality' value='High' />
        					<param name='SAlign' value='' />
        					<param name='Menu' value='-1' />
        					<param name='Base' value='' />
        					<param name='AllowScriptAccess' value='sameDomain' />
        					<param name='Scale' value='ShowAll' />
        					<param name='DeviceFont' value='0' />
        					<param name='EmbedMovie' value='0' />
        					<param name='BGColor' value='FFFFFF' />
        					<param name='SWRemote' value='' />
        					<param name='MovieData' value='' />
        					<param name='SeamlessTabbing' value='1' />
        					<param name='Profile' value='0' />
        					<param name='ProfileAddress' value='' />
        					<param name='ProfilePort' value='0' />
        					<embed src={require('../flash/ClubSeo - 2006.05.01.swf')} quality='high' bgcolor='#ffffff' width='600'
        						height='400' name='ClubSeo - 2006.05.01' align='middle' allowScriptAccess='sameDomain'
        						type='application/x-shockwave-flash' pluginspage='http://www.macromedia.com/go/getflashplayer' />
        				</object>
                    </div>
                </Card>

                <Card elevation={Elevation.TWO} className='development-section'>
                    <h3>
                        RTTL2iMelody Converter
                    </h3>

                    <div>
                        This application was created to perform RTTL ringtone conversions to iMelody.  When this was developed, RTTL
                        was a prevalent form of adding custom ringtones to cellphones.  The Motorola phones used a
                        format called iMelody.  I created this application to convert RTTL to iMelody. The application,
                        developed in .NET, allows the user to copy and paste the RTTL code, and use the converted iMelody
                        code to add to their Motorola phone.
                    </div>

                    <div className='rttl dev-center'>
                        <a href={require('../files/RTTL2IMelody_v0.4.rar')}>
                            <img alt='Click to download.' src={require('../images/development/ringtone.jpg')} />
                        </a>
                    </div>

                </Card>
            </div>
        );
    }
}
export default Development;
