import React, { Component } from 'react';

import { Button, Card, Elevation } from "@blueprintjs/core";

class Arcade extends React.Component {
  render() {
    const { name = 'Arcade' } = this.props;
    return (
        <div className='arcade'>
            <Card elevation={Elevation.TWO} className='overview'>
                <h3>
                    Overview
                </h3>

                <div>
                <p>
                About 10 years ago, I built my own arcade. This arcade cabinet was designed on a semi-limited budget. Total out of pocket was just under $150.
                I did a lot of research before actually getting started, pricing out the wood and any other parts I needed, and how best to use the parts I already had.
                </p>

                <p>
                This was not a true MAME cabinet by some standards in that the backbone was not a PC. I instead used a modified original XBOX.
                This gave me some wiggle room in the control field without worrying about keyboard modding, or dealing with keyboard controllers.
                </p>

                <p>
                From the beginning I had an available XBOX, CRT TV, and the many tools needed.
                I opted out of using the pre-built dual X-Arcade Joystick and instead purchased two used Pelican Universal Arcade Controllers.
                I shopped around for plexiglass for the marquee as well as a light to go behind it.  This was a fairly cheap, very cool project to embark.
                </p>

                <p>
                I have a full price breakdown in my <a href={require('../files/Arcade-Cabinet-rev2.doc')}> Design Document </a> that
                I created while researching and designing my cabinet.
                </p>


                </div>

                <div className='images'>
                    <img src={require('../images/arcade/asm7.jpg')} />
                </div>
            </Card>

            <Card elevation={Elevation.TWO}>
                <h3>
                    Design
                </h3>

                <div>
                    I spent a LOT of time on design. It was definitely a good thing but not really what I wanted to do.
                    I was quite impatient to get started, but at the time I was in the process of buying a house and moving. I spent the free time
                    I had designing the cabinet to the last tiny detail.
                </div>

                <div className='sub'>
                    <div>
                        As you can see by the image on the right, I took the time to color in my design, add the components I planned to use,
                        and paste in my marquee. At this point in the design I did plan to use the X-Arcade controller.
                    </div>

                    <img src={require('../images/arcade/design1.gif')} />
                </div>

                <div className='sub'>
                    <img src={require('../images/arcade/design2.gif')} />
                    <div>
                        The image on the left is one of four layouts I used for measurements for the wood.
                        The other three are in my design document which is available <a href={require('../files/Arcade-Cabinet-rev2.doc')}>here</a>.
                        My design was based loosely from LuSiD's Arcade Flashback. I widen my cabinet some since I was
                        using a larger TV, and I also choose to have my cabinet set up more like an entertainment center
                        rather than a typical cabinet by including shelves.
                    </div>
                </div>

                <div>
                    <p>
                        All in all I probably put about a month into the design phase of my arcade cabinet. But at least I can say it was well worth it.
                    </p>

                    <p>
                        The design was done in Microsoft Visio. This allowed the drawings to be to scale and let
                        me be sure that I was using the 8'x4' sheets of wood as efficiently as possible.
                    </p>
                </div>
            </Card>

            <Card elevation={Elevation.TWO}>
                <h3>
                    Control Panel
                </h3>

                <div>
                    I followed the advice of a few people and decided to focus the first part of the assembly phase on the control panel.
                </div>

                <div className='sub'>
                    <div>
                        I purchased two used Pelican Universal Arcade controllers and gutted them.
                        This allowed me to use their precut top as a template for my control panel. While I could have easily changed the button layout,
                        I decided that Pelican's worked well enough for me.
                    </div>

                    <img src={require('../images/arcade/cp-pelican1.jpg')} />
                </div>

                <div>
                    I wanted to take the control panel one step further than to simply transfer the buttons from the Pelican Controllers
                    over to my control panel box. My relatives have always been Pinball fans, and I figured I'd like to give them something
                    to play when they come over. I bought a few extra buttons and added pinball flippers and pinball release
                    button on the sides and front of the control panel box. They are tied into the buttons on Player 1's set of controls.
                </div>

                <div className='sub'>
                    <img src={require('../images/arcade/cp-pelican2.jpg')} />

                    <div>
                        The two pelican controllers were slightly different releases, and some of the guts were mounted in
                        different spots, but this didn't make it any more difficult to work with. I carefully unplugged
                        wires that had to come off, removed the microswitches, took out all the buttons, and put them
                        inside my control panel box. (Pictured is the inside of the Pelican Joystick)
                    </div>
                </div>

                <div>
                    Holes for the control panel were cut using a 1 1/8" hole saw.
                </div>

                <div className='sub'>
                    <img src={require('../images/arcade/cp1.jpg')} />
                    <img src={require('../images/arcade/cp2.jpg')} />
                </div>

                <div>
                    After the holes were cut I did some minor sanding to smooth out any rough edges,
                    and then covered the top in contact paper with a wood grain print. Finally I added the buttons,
                    reattached the wires, and finished building the actual box.
                </div>

                <div className='sub'>
                    <img src={require('../images/arcade/cp3.jpg')} />
                    <img src={require('../images/arcade/cp4.jpg')} />
                </div>
            </Card>

            <Card elevation={Elevation.TWO}>
                <h3>
                    Assembly
                </h3>

                <div>
                    Total assembly of the arcade cabinet took about 2 1/2 to 3 weeks at about 40-50 total hours of work.
                    A majority of the time was spent measuring the design onto the wood, cutting the pieces, sanding,
                    cutting groves for the T-Molding and putting on the contact paper. Putting all of the pieces together
                    took about 4 hours of straight work.
                </div>

                <div className='sub'>
                    <div>
                        I took my design on paper and transferred it over to the 4x8 sheets of particle board.
                        The measure twice, cut once rule saved me a few times.  Since I managed to make use of all
                        but 5% of all the wood, I didn't leave much room for error.  (Though I did cut enough for two
                        full Control Panels incase I wasn't happy with the first design)
                    </div>

                    <img src={require('../images/arcade/asm1.jpg')} />
                </div>

                <div className='sub'>
                    <img src={require('../images/arcade/asm2.jpg')} />
                    <div>
                        <p>
                            Cutting the pieces alone was about a week of work.  Since 4x8 particle board has some
                            weight to it, I made sure that once I started a sheet that all parts were cut before
                            I stopped for the day.  It's a lot easier to carry 2'x2' sheets of particle board rather
                            and drag the 4'x8' sheet 20 feet from my garage to picnic table.
                        </p>
                        <p>
                            I made good use of my Yard Stick, Skill Saw and Pencil during this phase.
                        </p>
                    </div>
                </div>

                <div className='sub'>
                    <div>
                        As I found during the assembly phase of the control panel, it's much much easier to cut the
                        T-Molding notches BEFORE putting the contact paper on the particle board. I ended up using a
                        dremel (carefully) instead of a router. To use a dremel, use a ruler, and draw a
                        line across the edge of the particle board where you want the slot to be. Then patiently follow
                        that line with the dremel. Note however that 30,000rpms + particle board = smoke. You
                        essentially burn through the wood, not cut through it.
                    </div>
                    <img src={require('../images/arcade/asm3.jpg')} />
                </div>

                <div className='sub'>
                    <img src={require('../images/arcade/asm4.jpg')} />
                    <div>
                        <p>
                            The next step was to cover all the pieces in contact paper.
                            I went back and forth on what to ultimately do - either black chalkboard or wood grain.
                            As seen, I went with the wood grain which I think was a good end choice. I originally
                            owned a pool table that almost exactly matched the contact
                            paper I bought.
                        </p>
                        <p>
                            This was a fairly easy step. With the exception of the long 6' sides, everything was
                            easily done with just one person. Though it may have helped that I have too much
                            experience with installing large vinyl graphics on the sides of cars.
                        </p>
                        <p>
                            Immediately after adding the contact paper, I added the T-Molding on the necessary pieces.
                        </p>
                    </div>
                </div>

                <div>
                    Finally it was time for assembly! While I anticipated this being a two person ordeal,
                    it was actually quite simple. I started by attaching the support beams for the bottom shelf
                    to the back panel of the arcade. That allowed me to add the bottom shelf and the front kick
                    panel. With that done, I now had something to support the sides. I thought I was going to be
                    stopped dead with the middle shelves, but I modified my design slightly to make this easier.
                    Instead of having stationary shelves, I carefully measured the height for the location of the screws,
                    and just sat the shelves on the screws. This allows for easy removal if I decide to add something
                    larger to the whole system. I used a similar step for the monitor shelf, but then also securely
                    screwed the monitor shelf in place and added a support beam underneath for the hefty 27" CRT TV.
                </div>

                <div className='sub'>
                    <img src={require('../images/arcade/asm5.jpg')} />
                    <img src={require('../images/arcade/asm6.jpg')} />
                </div>

                <div>
                    I then installed the marquee and speaker shelf, as well as the TV and XBOX to get the finished product.
                </div>

                <div className='sub'>
                    <img src={require('../images/arcade/asm7.jpg')} />
                </div>

            </Card>

            <Card elevation={Elevation.TWO}>
                <h3>
                    Marquee
                </h3>

                <div>
                    During the design process of the cabinet, I did a bit of research on marquees.
                    I hit a few points where I was going to borrow one of the MAME marquees that several people have,
                    but I like to try to be some what original at times. Another thought was to purchase a
                    classic original from ebay, but I did not have one particular game marquee that I wanted.
                    If anything, maybe Super Mario Bros. but at the time they were in short supply. I finally decided I just
                    needed to flat out design one. Since the modded XBOX has the emulators for every system that came before it,
                    I figured I'd just make the sign with the logos scattered. My first marquee was the one pictured at the
                    top of this page and the one I ultimately used.
                </div>

                <div className='sub'>
                    <img src={require('../images/arcade/mar1.jpg')} />
                </div>

                <div>
                    I designed a second marquee that was a combination of the video
                    game system logos and the word ARCADE. (Originality at its best.) This marquee is also pictured.
                    I had them both printed out, but after asking which one most people liked, the first one ended up winning.
                </div>

                <div className='sub'>
                    <img src={require('../images/arcade/mar2.jpg')} />
                </div>

                <div>
                    I'm frugal when it comes to buying, well, anything. So instead of buying the Marquee frames that are
                    available, I made my own frame out of particle board, and wrapped it in the wood grain
                    contact paper. I sandwiched my printed marquee between two sheets of Plexiglas and screwed them into the
                    back of the frame.
                </div>

                <div className='sub'>
                    <img src={require('../images/arcade/marquee1.jpg')} />
                    <img src={require('../images/arcade/marquee.jpg')} />
                </div>
            </Card>

            <Card elevation={Elevation.TWO}>
                <h3>
                    Final
                </h3>

                <div>
                    Not much to say here except LETS PLAY! This arcade was a huge hit amongst friends.  With the XBOX, upto four people could play by adding on a few wireless controllers.
                </div>

                <div className='images'>
                    <img src={require('../images/arcade/asm7.jpg')} />
                </div>

                <div>
                    <h4>XBOX Hardware:</h4>
                    <div>
                        <p>Modded with Executer Mod Chip</p>
                        <p>Upgraded 120gb harddrive</p>
                        <p>Lots of wireless controllers, DDR Pads, and Lights Guns</p>
                    </div>

                    <h4>XBOX Software:</h4>
                    <div>
                        <p>Evolution X Dashboard</p>
                        <p>NES, SNES, N64, GB, GBC, GBA Emulators</p>
                        <p>Genesis & PS1 Emulators as well</p>
                        <p>XBOX Media Center (XBMC)</p>
                    </div>


                    <h4>Best Arcade Cabinet Friendly XBOX Games:</h4>
                    <div>
                        <p>House of the Dead 2/3</p>
                        <p>Pure Pinball</p>
                        <p>Street Fighter Anniversary Collection</p>
                        <p>Ultimate Pro Pinball</p>
                    </div>

                    <h4>Tools Used:</h4>
                    <div>
                        <p>Skill/Circular Saw</p>
                        <p>Reciprocating Saw</p>
                        <p>Hole Saw - 1 1/8" for Buttons, 2" for Cables</p>
                        <p>Yard Stick</p>
                        <p>Dremel</p>
                        <p>Drill</p>
                    </div>
                </div>
            </Card>
        </div>
    );
  }
}
export default Arcade;
