import React, { Component } from 'react';

import { Button, Card, Elevation } from "@blueprintjs/core";

class Random extends React.Component {
  render() {
    return (
        <div className='random'>
            I have so many hobbies...

            <Card elevation={Elevation.TWO} className='development-section'>
                <h3>
                    Perler Beads
                </h3>

                <div className='random-pictures perler'>
                    <img src={require('../images/random/perler-coasters.jpg')} />
                    <img src={require('../images/random/perler-mario.jpg')} />
                </div>
            </Card>

            <Card elevation={Elevation.TWO} className='development-section'>
                <h3>
                    Room Design
                </h3>

                <div className='random-pictures bar'>
                    <img src={require('../images/random/barpics/bar-design.png')} />
                    <img src={require('../images/random/barpics/bar-finished.jpg')} />
                </div>
            </Card>
        </div>
    );

  }
}
export default Random;
