import React, { Component } from 'react';

import { Button, Card, Elevation } from "@blueprintjs/core";

class Trunk extends React.Component {
  render() {
    return (
        <div className='custom-trunk'>
            <Card elevation={Elevation.TWO}>
                <h3>
                    Custom Trunk Enclosure
                </h3>
                <table width="85%">
                    <tbody>
                        <tr>
                            <td>
                                <p>
                                    First we start off with a selection of random just parts lying around and try
                                    to wonder what to do with them all. A sensible person would throw it all away
                                    or sell it on eBay because it's just junk.
                                </p>
                                <p>But you decide to put it all together, find out you have something that works,
                                    and say "I know, I'll put it in the car!"
                                </p>
                                <p align="center"><img src={require('../../images/automotive/comp1.jpg')} />
                                </p>
                                <p>Well, now how do I fit all that in the car and make it look good? At this point
                                    I still needed to finish my trunk. It had been "in progress" for about 9
                                    months, so I might as well do something about it. I started out designing it
                                    with my impressive MS Paint skills.
                                </p>
                                <p>I started buying parts that I needed for what should be a <i>cheap</i> project (because I
                                    already have some of the parts). Wood, tools I realized I needed, a
                                    smaller keyboard (the one in the top image was the 'new' small keyboard), a
                                    power inverter, a FM transmitter, rollers for the sliding drawer... <b>Cheap </b>
                                    project, remember?
                                </p>
                                <p><img src={require('../../images/automotive/comp2.jpg')} align="right" />As I was waiting for more parts to
                                    arrive from ebay, I worked more with MS Paint to get a few better looking
                                    designs.
                                </p>
                                <p align="center">
                                    <img src={require('../../images/automotive/comp3.jpg')} />
                                </p>
                                <p align="center">&nbsp;</p>
                                <p>I then started measuring the trunk, and realized that Paseo trunks are small and
                                    that the original plans just would not work. (No room for circus clowns, rubber
                                    chickens, and a race track.) I had space limitations here.
                                </p>
                                <p>I used a piece of cardboard to cut the design. This was much easier to carry
                                    around between the car and three floors up to my apartment. I took the
                                    cardboard design and traced it around the wood.
                                </p>
                                <p>Then I made the necessary cuts: the holes, rectangles, sliding drawer.
                                </p>
                                <p><img src={require('../../images/automotive/comp4.jpg')} /></p>
                                <p>Here is a hint for everyone: Liquid nail is the cure for everything. Forget
                                    about all other glue and nails. This is the way to go. Well, almost. I used a
                                    few screws for added support, but at least a tube of liquid nail on this
                                    project. The carpet is liquid nailed down (quite well compare to what I
                                    expected.) Just don't use too much or it will seep through.
                                </p>
                                <p>
                                    Now if you do this correctly, everything will fall right into place. Measure
                                    once, cut twice. That does not sound quite right. Reverse that. Even then,
                                    sometimes you have to cut twice when you miss-judge a lip in the trunk.
                                    Sometimes it is safer cut less than you think is correct, check it, and cut
                                    again.
                                </p>
                                <p>Finally you will end with something looking a little like this. Assuming you
                                    ignored all my advice, hired a professional, and paid more than you expected.
                                    In all seriousness, this is not a hard project to tackle. After the design
                                    process was completed, the rest may have taken only two weeks to complete.
                                </p>
                                <p align="right"><img src={require('../../images/automotive/comp5.jpg')} /></p>
                                <p><b>Parts used:</b>
                                    <br />
                                    -500Mhz AMD Athlon Processor.
                                    <br />
                                    -128Mb Ram.
                                    <br />
                                    -Random motherboard that supports AMD.
                                    <br />
                                    -64Mb Graphics card with TV out.
                                    <br />
                                    -Soundblaster Soundcard.
                                    <br />
                                    -TV-in card.
                                    <br />
                                    -10Gb Harddrive.
                                    <br />
                                    -DVD-Rom + CD-RW
                                    <br />
                                    -5" LCD screen.
                                    <br />
                                    -USB port.
                                    <br />
                                    -400W Power inverter.
                                    <br />
                                    -FM Transmitter.
                                    <br />
                                    -Mini Keyboard with touchpad mouse.
                                </p>
                                <p></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Card>
        </div>
    );
  }
}
export default Trunk;
