import React from 'react';
import logo from './logo.svg';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import {
    Alignment,
    AnchorButton,
    Button,
    Classes,
    H5,
    Navbar,
    NavbarDivider,
    NavbarGroup,
    NavbarHeading,
} from '@blueprintjs/core';

//import { Breadcrumb, Breadcrumbs, IBreadcrumbProps, Icon } from '@blueprintjs/core';

import About from './About/About';
import Arcade from './Arcade/Arcade';
import Automotive from './Automotive/Automotive';
import Whitepaseo from './Automotive/Whitepaseo/Whitepaseo';
import Blackpaseo from './Automotive/Blackpaseo/Blackpaseo';
import Blackpaseo2 from './Automotive/Blackpaseo2/Blackpaseo2';
import Xb from './Automotive/Xb/Xb';
import Corners from './Automotive/Corners/Corners';
import Trunk from './Automotive/Trunk/Trunk';
import Development from './Development/Development';
import Printing from './Printing/Printing'
import Interview from './Development/Interview/Interview'
import Home from './Home/Home';
import Homebrewing from './Homebrewing/Homebrewing';
import Random from './Random/Random';
import Videos from './Videos/Videos';


import './App.scss';

// const BREADCRUMBS: IBreadcrumbProps[] = [
//     { href: '/development', icon: 'folder-close', text: 'Development' },
//     { href: '/development/flash', icon: 'folder-close', text: 'Flash' },
//     { icon: 'document', text: 'image.jpg' },
// ];




function App() {
   //  const renderCurrentBreadcrumb = ({ text, ...restProps }: IBreadcrumbProps) => {
   //     // customize rendering of last breadcrumb
   //     return <Breadcrumb {...restProps}>{text} <Icon icon='star' /></Breadcrumb>;
   // };
  return (
    <div className='App'>
        <Router>
            <Navbar>
                <NavbarGroup>
                    <NavbarHeading className='heading-large'>insaneintention designs</NavbarHeading>
                    <NavbarHeading className='heading-small'>iid</NavbarHeading>
                    <NavbarDivider />
                    <AnchorButton href='/' className={Classes.MINIMAL} icon='home' text='Home' />
                    <AnchorButton href='/development' className={Classes.MINIMAL} icon='code' text='Development' />
                    <AnchorButton href='/printing' className={Classes.MINIMAL} icon='cube' text='3D Printing' />
                    <AnchorButton href='/arcade' className={Classes.MINIMAL} icon='desktop' text='Arcade' />
                    <AnchorButton href='/automotive' className={Classes.MINIMAL} icon='drive-time' text='Automotive' />
                    {/*<AnchorButton href='/homebrewing' className={Classes.MINIMAL} icon='glass' text='Homebrewing' />*/}
                    <AnchorButton href='/videos' className={Classes.MINIMAL} icon='mobile-video' text='Videos' />
                    <AnchorButton href='/random' className={Classes.MINIMAL} icon='asterisk' text='Random' />
                    <NavbarDivider />
                    <AnchorButton href='/about' className={Classes.MINIMAL} icon='help' text='About' />
                </NavbarGroup>
            </Navbar>

{/*
            <Breadcrumbs
                currentBreadcrumbRenderer={renderCurrentBreadcrumb}
                items={BREADCRUMBS}
            />
*/}
            <div className='main-content'>
                <Switch>
                    <Route path='/about'>
                        <About />
                    </Route>
                    <Route path='/arcade'>
                        <Arcade />
                    </Route>
                    <Route path='/automotive/whitepaseo'>
                        <Whitepaseo />
                    </Route>
                    <Route path='/automotive/blackpaseo'>
                        <Blackpaseo />
                    </Route>
                    <Route path='/automotive/blackpaseo2'>
                        <Blackpaseo2 />
                    </Route>
                    <Route path='/automotive/xb'>
                        <Xb />
                    </Route>
                    <Route path='/automotive/corners'>
                        <Corners />
                    </Route>
                    <Route path='/automotive/trunk'>
                        <Trunk />
                    </Route>
                    <Route path='/automotive'>
                        <Automotive />
                    </Route>
                    <Route path='/development/interview'>
                        <Interview />
                    </Route>
                    <Route path='/development'>
                        <Development />
                    </Route>
                    <Route path='/printing'>
                        <Printing />
                    </Route>
                    <Route path='/homebrewing'>
                        <Homebrewing />
                    </Route>
                    <Route path='/random'>
                        <Random />
                    </Route>
                    <Route path='/videos'>
                        <Videos />
                    </Route>
                    <Route path='/'>
                        <Home />
                    </Route>
                </Switch>
            </div>

            <div className='copywrite'> insaneintention designs © 2006 - 2021 </div>

        </Router>
    </div>
  );
}

export default App;
