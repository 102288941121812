import React, { Component } from 'react';

import { Button, Card, Elevation } from "@blueprintjs/core";

class Automotive extends React.Component {
  render() {
    return (
        <div className='automotive'>
            <div>
                This page shows different automotive projects I've been involved in.
            </div>

            <div className='col'>
                <Card interactive={true} elevation={Elevation.TWO} className='development-section' onClick={() => window.location = '/automotive/whitepaseo'}>
                    <h3>
                        1997 Toyota Paseo Coupe
                    </h3>

                    <div>
                        <a href='/automotive/whitepaseo'><img src={require('../images/automotive/whiteseo.jpg')} /></a>
                    </div>
                </Card>

                <Card interactive={true} elevation={Elevation.TWO} className='development-section' onClick={() => window.location = '/automotive/blackpaseo'}>
                    <h3>
                        1997 Toyota Paseo Convertible
                    </h3>

                    <div>
                        <a href='/automotive/blackpaseo'><img src={require('../images/automotive/blackseo.jpg')} /></a>
                    </div>
                </Card>
            </div>

            <div className='col'>
                <Card interactive={true} elevation={Elevation.TWO} className='development-section' onClick={() => window.location = '/automotive/blackpaseo2'}>
                    <h3>
                        1997 Toyota Paseo Convertible Ver. 2
                    </h3>

                    <div>
                        <a href='/automotive/blackpaseo2'><img src={require('../images/automotive/blackseo2.jpg')} /></a>
                    </div>
                </Card>

                <Card interactive={true} elevation={Elevation.TWO} className='development-section' onClick={() => window.location = '/automotive/xb'}>
                    <h3>
                        2008 Scion xB
                    </h3>

                    <div>
                        <a href='/automotive/xb'><img src={require('../images/automotive/xb.jpg')} /></a>
                    </div>
                </Card>
            </div>

            <div className='col'>
                <Card interactive={true} elevation={Elevation.TWO} className='development-section' onClick={() => window.location = '/automotive/corners'}>
                    <h3>
                        Custom Headlight Corners
                    </h3>

                    <div>
                        <a href='/automotive/corners'><img src={require('../images/automotive/proj2.jpg')} /></a>
                    </div>
                </Card>

                <Card interactive={true} elevation={Elevation.TWO} className='development-section' onClick={() => window.location = '/automotive/trunk'}>
                    <h3>
                        Custom Trunk Layout
                    </h3>

                    <div>
                        <a href='/automotive/trunk'><img src={require('../images/automotive/proj1.jpg')} /></a>
                    </div>
                </Card>
            </div>
        </div>
    );
  }
}
export default Automotive;
