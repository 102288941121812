import React, { Component } from 'react';

import { Button, Card, Elevation } from "@blueprintjs/core";

class Blackpaseo2 extends React.Component {
  render() {
    return (
        <div>
        <Card elevation={Elevation.TWO}>
        <table id="mods" width="30%" align="left" border="1">
            <tbody><tr>
                <td><b>1997 Toyota Paseo Convertible</b>
                </td>
            </tr>
            <tr>
                <td>Purchased: May 2005
                </td>
            </tr>
            <tr>
                <td>
                    Status: Sold 2015
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td><b>Modifications</b>
                </td>
            </tr>
            <tr>
                <td><u>Exterior</u>
                </td>
            </tr>
            <tr>
                <td>
                    -Mayr Projector Headlights
                </td>
            </tr>
            <tr>
                <td>
                    -Custom Corner Lights
                </td>
            </tr>
            <tr>
                <td>-Cynos Fog Lights
                </td>
            </tr>
            <tr>
                <td>-16" Riax Rims
                </td>
            </tr>
            <tr>
                <td>-Custom Satin Paint
                </td>
            </tr>
            <tr>
                <td>-Custom Tinted Taillights
                </td>
            </tr>
            <tr>
                <td>-Blue Underglow LED Kit
                </td>
            </tr>
            <tr>
                <td>-Blue Strobes
                </td>
            </tr>
            <tr>
                <td>
                    -Cynos Front/Side Body Kit
                </td>
            </tr>
            <tr>
                <td>
                    -Onderground-Design Rear Body Kit
                </td>
            </tr>
            <tr>
                <td>-Fsudo Muffler
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td><u>Interior</u>
                </td>
            </tr>
            <tr>
                <td>-Reupholstered Seats
                </td>
            </tr>
            <tr>
                <td>-Painted Interior
                </td>
            </tr>
            <tr>
                <td>-Died Carpet
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td><u>Audio</u>
                </td>
            </tr>
            <tr>
                <td>-Pioneer MP3 Headunit
                </td>
            </tr>
            <tr>
                <td>-Poineer Speakers
                </td>
            </tr>
            <tr>
                <td>-Pioneer 10" Sub
                </td>
            </tr>
            <tr>
                <td>-Crunch Amp
                </td>
            </tr>
            <tr>
                <td>-Two rear seat 10" Subs
                </td>
            </tr>
            <tr>
                <td>-Kenwood Amp
                </td>
            </tr>
            <tr>
                <td>-Custom Enclosure
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td><u>Engine</u>
                </td>
            </tr>
            <tr>
                <td>-Strut Bar
                </td>
            </tr>
            <tr>
                <td>-Weapon R Intake
                </td>
            </tr>
            <tr>
                <td>-Grounding Kit
                </td>
            </tr>
            <tr>
                <td>-NGK Plugs &amp; Wires
                </td>
            </tr>
            <tr>
                <td>-5-speed Transmission Swap
                </td>
            </tr>

        </tbody></table>

            <div className='car-images images'>
                <img src={require('../../images/automotive/bp21.jpg')} />
                <img src={require('../../images/automotive/bp22.jpg')} />
                <img src={require('../../images/automotive/bp23.jpg')} />
                <img src={require('../../images/automotive/bp24.jpg')} />
                <img src={require('../../images/automotive/bp25.jpg')} />
            </div>
        </Card>
        </div>
    );
  }
}
export default Blackpaseo2;
