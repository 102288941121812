import React, { Component } from 'react';

import { Button, Card, Elevation } from "@blueprintjs/core";

class Xb extends React.Component {
  render() {
    return (
        <div>
        <Card elevation={Elevation.TWO}>
        <table id="mods" width="30%" align="left" border="1">
            <tbody><tr>
                <td><b>2008 Scion xB</b>
                </td>
            </tr>
            <tr>
                <td>Purchased: Aug 2007
                </td>
            </tr>
            <tr>
                <td>
                    Status: Sold 2017
                </td>
            </tr>
            <tr>
                <td>
                </td>
            </tr>
            <tr>
                <td><b>Modifications</b>
                </td>
            </tr>
            <tr>
                <td><u>Exterior</u>
                </td>
            </tr>
            <tr>
                <td>
                    -HID Headlights
                </td>
            </tr>
            <tr>
                <td>
                    -Tinted Taillights
                </td>
            </tr>
            <tr>
                <td>-Inno Roof Racks
                </td>
            </tr>
            <tr>
                <td>-17" Enkei Rimes
                </td>
            </tr>
            <tr>
                <td>-7-Color Underglow LED Kit
                </td>
            </tr>
            <tr>
                <td><u>Interior</u>
                </td>
            </tr>
            <tr>
                <td>-Stock
                </td>
            </tr>
            <tr>
                <td><u>Audio</u>
                </td>
            </tr>
            <tr>
                <td>-Alpine DVD/GPS/MP3 Player
                </td>
            </tr>
            <tr>
                <td>-Two 8" Subs
                </td>
            </tr>


            <tr>
                <td><u>Engine</u>
                </td>
            </tr>
            <tr>
                <td>-Stock
                </td>
            </tr>


        </tbody></table>

            <div className='car-images images'>
                <img src={require('../../images/automotive/xb1.jpg')} />
                <img src={require('../../images/automotive/xb2.jpg')} />
                <img src={require('../../images/automotive/xb3.jpg')} />
                <img src={require('../../images/automotive/xb4.jpg')} />
                <img src={require('../../images/automotive/xb5.jpg')} />
            </div>
            </Card>
        </div>
    );
  }
}
export default Xb;
